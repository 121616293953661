<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="编辑"
      @ok="handleSubmit"
    >
      <a-form
        class="simiot-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >

        <a-form-item label="行业">
          <a-input
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入行业' },
                { max: 50, message: '最多50个字符' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="说明">
          <a-textarea
            v-decorator="['description', {
              rules: [
                { max: 200, message: '最多200个字符' }
              ]
            }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { findIndustryTypeForm, updateIndustryType } from '@/api/industry_type'

export default {
  name: 'EditIndustryType',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'industry_type' }),
      submitting: false,
      data: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.form.getFieldDecorator('keys', { initialValue: [], preserve: true })
    this.fetchFormData()
  },
  methods: {
    fetchFormData() {
      findIndustryTypeForm(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.form.setFieldsValue(res.data)
        }
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values
          }
          updateIndustryType(this.id, data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }

  }
}
</script>
